export const EXTENSION_TO_MIME = {
    '.png': ['image/png'],
    '.jpeg': ['image/jpeg'],
    '.jpg': ['image/jpeg'],
    '.svg': ['image/svg+xml'],
    '.gif': ['image/gif'],
    '.zip': ['application/x-zip-compressed', 'application/zip'],
    '.pdf': ['application/pdf'],
    '.webp': ['image/webp'],
} as const;

export function validateExtension(file: File, supported_extensions: string[]): void {
    if (!supported_extensions.some((ext) => EXTENSION_TO_MIME[ext].includes(file.type))) {
        throw new Error(`Wrong file extension. Supported extensions: ${supported_extensions}`);
    }
}
