import { ContentCategory } from '../type';

export const PORTAL_DOMAIN = 'gitnation.com';
export const PORTAL_ORIGIN = 'https://' + PORTAL_DOMAIN;

export const DEFAULT_TIMEZONE = 'Europe/Amsterdam';
export const DIGITAL_BADGES_OG_IMAGE = 'https://digital-badges-og-image.vercel.app';
export const OG_IMAGE_SERVICE_URL = 'https://gn-portal-og-images.vercel.app';
export const REDIRECT_ROUTES = ['/profile', '/my-bookmarks', '/events/my', '/my-team', '/my-history'];
export const VIDEO_RELEASE_DELAY_IN_DAYS = 30;
export const VIDEO_RELEASE_DELAY_OFFSET_FOR_REGISTERED = 15;

export const UPLOAD_PRESET = process.env.NEXT_PUBLIC_UPLOAD_PRESET || 'nrqvp1pp';

export const JOB_ROLES = [
    'Front-end Engineer',
    'Back-end Engineer',
    'Full-stack Engineer',
    'Tech Lead',
    'Software Architect',
    'Test Engineer',
    'Student/Intern',
    'Other',
];

export const VISIBLE_CATEGORIES: ContentCategory[] = [
    ContentCategory.Talk,
    ContentCategory.Article,
    ContentCategory.Workshop,
    ContentCategory.Video,
];

export const JS_EVENT_BRAND_MATCHER = {
    OR: [
        { name: { contains: 'js', mode: 'insensitive' as const } },
        { name: { contains: 'Node' } },
        { name: { contains: 'TS' } },
    ],
};

export const REACT_EVENT_BRAND_MATCHER = {
    OR: [
        { name: { contains: 'React', mode: 'insensitive' as const } },
        { name: { contains: 'Remix', mode: 'insensitive' as const } },
    ],
};

export const TWEET_LIMIT = 280;

export const FIRST_LOAD_COMMENTS_TAKE = 3;
