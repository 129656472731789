import {
    Content,
    Brand,
    Event,
    Tag,
    User,
    EventRegistration,
    ContentRegistration,
    NotificationSubscription,
    FaqCategory,
    FaqItem,
    Subscription,
    UserOnBookmark,
} from '@gn/db';

import { ContentSelect } from './utils/contentSelectSchema';
import { ContentChapter } from '@gn/db/src/client';
import { Lang } from './localization';
import { getGlobalLogger } from './logger';

declare global {
    var gnLogger: ReturnType<typeof getGlobalLogger>;
}

export interface TimeSeriesDataPoint {
    group: string;
    date: number;
    value: number;
    surplus?: number;
    sorting?: number;
}

export type EventWithBrand = Event & {
    brand: Brand;
};

export type EventRegistrationWithEvent = EventRegistration & {
    event: Event;
};

export type ContentRegistrationWithContent = ContentRegistration & {
    content: Content;
};

export type ContentWithEvent = Content & {
    event?: EventWithBrand | null;
    tags?: Tag[];
    users: User[];
    chapters: ContentChapter[];
    localizations?: { lang: Lang; title: string; slug: string }[];
};

export type ContentWithUsers = ContentSelect & {
    users?: User[];
};

export type EventWithContentWithUsers = EventWithBrand & {
    Content: ContentWithUsers[];
};

export enum PartnerRole {
    Sponsor = 'sponsor',
    Community = 'community',
    Media = 'media',
}

export enum UserRole {
    Customer = 'customer',
    Resident = 'resident',
    Citizen = 'citizen',
    CoreTeam = 'coreteam',
    User = 'user',
    Unauthorized = 'unauthorized',
}

export enum AccessType {
    Public,
    Regular,
    Premium,
    Draft,
}

export enum ContentCategory {
    Talk,
    Article,
    Workshop,
    Video,
    DiscussionRoom,
    SpeakersRoom,
    QnA,
    Announcement,
}

export enum ContentPublicationStatus {
    Draft,
    Upcoming,
    Published,
    Pending,
    Finished,
}

export type ContentMetadata = {
    // email scheduling, scheduled emails cancellation
    sendgridBatchId?: string;
    isWorkshopReminderDisabled?: boolean;
} | null;

export type Promocode = {
    eventId?: number;
    percent: number;
    promocode: string;
    startDate: string;
    endDate: string;
};

export enum MailingSettings {
    MONTHLY_CONTENT_DIGEST = 'monthlyContentDigest',
    SPEAKER_EMAILS = 'speakerEmails',
    EVENTS_ANNOUNCEMENTS = 'eventsAnnouncements',
}

export const MailingSettingsTitle: { [key in MailingSettings]: string } = {
    [MailingSettings.MONTHLY_CONTENT_DIGEST]: 'Monthly content digest',
    [MailingSettings.SPEAKER_EMAILS]: 'Updates about your talks and workshops (for speakers)',
    [MailingSettings.EVENTS_ANNOUNCEMENTS]: 'GitNation Conferences updates',
};

export interface ContentLink {
    label: string;
    value: string;
}

export interface ContentLinks {
    slides: ContentLink;
    github: ContentLink;
    projectWebsite: ContentLink;
}

export enum ContentLinksLabels {
    slides = 'Slides',
    github = 'Github',
    projectWebsite = 'Project website',
}

export interface OwnedSubscription {
    status: string;
    itemQuantity: number;
}

export interface UserWithSubscription
    extends Pick<
        User,
        'id' | 'name' | 'nickname' | 'avatar' | 'company' | 'location' | 'multipassValidUntil' | 'role'
    > {
    subscriptions?: Pick<Subscription, 'status' | 'currentTermEnd' | 'currentTermStart' | 'itemTrialEnd'>[];
    ownedSubscriptions?: OwnedSubscription[];
    ticketGroups?: { groupName: string | null; purchaseDate: Date | null }[];
    bookmarks?: Pick<UserOnBookmark, 'contentId'>[];
}

export type SubscriptionUser = Pick<User, 'id' | 'name' | 'email' | 'avatar'>;

export type MultipassUser = Pick<User, 'id' | 'multipassValidUntil' | 'role'> & {
    subscriptions?: Pick<Subscription, 'status' | 'currentTermEnd' | 'currentTermStart' | 'itemTrialEnd'>[];
};

export enum Role {
    Customer = 'customer',
    Resident = 'resident',
    Citizen = 'citizen',
    CoreTeam = 'coreteam',
    User = 'user',
    Unauthorized = 'unauthorized',
}

// see https://apidocs.chargebee.com/docs/api/subscriptions?prod_cat_ver=2#subscription_status
export enum SubscriptionStatus {
    Future = 'future',
    Trial = 'in_trial',
    Active = 'active',
    NonRenewing = 'non_renewing',
    Paused = 'paused',
    Cancelled = 'cancelled',
}

export enum TagsPriority {
    Highest = -2,
    Default = 0,
    Lowest = 2,
}

/** Utilities */
export type ArrayElement<T extends unknown[]> = T extends (infer ElementType)[] ? ElementType : unknown;

export interface ContentPopularityDataInflux {
    time: {
        _nanoISO: string;
        getNanoTime: () => number;
        getTime: () => number;
        toNanoISOString: () => string;
    };
    spread: 0;
    id: string;
}

export type UserEventRegistrationMetadata = null | {
    order?: number;
};

export interface NotificationWithUserAndContent extends Pick<NotificationSubscription, 'id'> {
    user: User;
    content: Content;
}

export type FaqCategoriesWithItems = Pick<FaqCategory, 'name'> & { items: Pick<FaqItem, 'question' | 'answer'>[] };

export const enum EventCategory {
    Upcoming = 'Upcoming',
    Past = 'Past',
    JS = 'JS',
    React = 'React',
    My = 'My',
}

export enum EventTab {
    SPEAKERS = 'speakers',
    TALKS = 'talks',
    WORKSHOPS = 'workshops',
    PARTNERS = 'partners',
}

export const EventRegistrationTicketType = {
    Badge: 'badge',
    FastRegister: 'fastRegister',
    Multipass: 'multipass',
    Live: 'live',
    Ticket: 'ticket',
    Speaker: 'speaker',
} as const;
export type EventRegistrationTicketType = typeof EventRegistrationTicketType[keyof typeof EventRegistrationTicketType];

export const ReactionIcon = {
    Like: 'like',
    Wow: 'wow',
    Rocket: 'rocket',
    Clap: 'clap',
    // ThumbsUp: 'thumbs-up',
    Party: 'party',
    Laugh: 'laugh',
    Thinking: 'thinking',
    Note: 'note',
} as const;
export type ReactionIcon = typeof ReactionIcon[keyof typeof ReactionIcon];

export type TrackFormat = 'Remote' | 'InPerson';

export enum BrandTheme {
    Light = 'Light',
    Dark = 'Dark',
}
