import { getCloudinaryImageUrl, CloudinaryTransformation } from '@gn/core/integrations/cloudinary';
import { Role } from '@gn/core/type';
import { Ticket } from '@gn/db/client';
import { MULTIPASS_SEASON_ONE, MULTIPASS_SEASON_ONE_END_DATE, MULTIPASS_SEASON_TWO } from 'configs/tickets';

export function getProfileUrl(user: { nickname?: string | null; id: number }): string {
    const name = user.nickname && encodeURI(user.nickname);
    return `/person/${name || user.id}`;
}

export function getName(user: { name: string | null }): string | null {
    return user.name;
}

export function getAvatar(user: { avatar?: string | null; role?: string | null }, width: 60 | 300 | 600 = 300): string {
    return (
        getCloudinaryImageUrl({
            url: user.avatar,
            width,
            height: width,
            transformations: [CloudinaryTransformation.fill],
            useImgix: !user.role || user.role === Role.User,
        })?.url ||
        undefined ||
        '/default-avatar.jpeg'
    );
}

export function getUserCountryAndCompany(user) {
    const { company, location } = user;
    const countryAndCompanyArray: Array<string> = [];
    user.company && countryAndCompanyArray.push(company);
    user.location && countryAndCompanyArray.push(location);
    return countryAndCompanyArray.join(', ').trim();
}

export function formatUserName(user: { name?: string | null; email: string }): string {
    return user.name || user.email;
}

export const getMultipassExpiryDate = (ticketGroups: { groupName: string | null; purchaseDate: Date }[]) => {
    const flatGroupNames = ticketGroups.map(({ groupName }) => groupName);

    if (flatGroupNames.includes(MULTIPASS_SEASON_TWO)) {
        const ticketObj = ticketGroups[flatGroupNames.indexOf(MULTIPASS_SEASON_TWO)];
        const yearFromThen = new Date(ticketObj.purchaseDate);
        yearFromThen.setFullYear(yearFromThen.getFullYear() + 1);
        return yearFromThen;
    }

    return flatGroupNames.includes(MULTIPASS_SEASON_ONE) ? new Date(MULTIPASS_SEASON_ONE_END_DATE) : null;
};

export const getClientTickets = (clients: { Ticket: Pick<Ticket, 'name'>[] }[]) =>
    clients
        .reduce((acc, c) => acc.concat(c.Ticket), [] as Pick<Ticket, 'name'>[])
        .filter((t) => !t.name.toLowerCase().includes('workshop') || !t.name.toLowerCase().includes('free'));
